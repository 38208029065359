<template>
  <div class="acivity-module">
    <img src="~@/assets/images/singleDays/banner.png" alt="">
    <p class="acivity-tips">活动时间：2021年11月8日 00：00 — 2021年11月11日 24：00</p>
    <div class="frame">
      <img src="~@/assets/images/singleDays/frame.png" alt="">
      <div class="btn" @click="jumpAction('vip')">去开通</div>
    </div>
    <div class="frame">
      <img src="~@/assets/images/singleDays/frame1.png" alt="">
      <div class="btn" @click="jumpAction('find')">去赢奖</div>
    </div>
    <div class="acivity-rule">
      <div class="rule-module">
        <p class="bold">福利一活动规则</p>
        <p class="red">1、活动时间：2021.11.08  00:00:00 ~ 2021.11.11  24:00:00</p>
        <p>
          2、活动内容：在活动规定时间内开通/续费会员的用户,可获得对应现金奖励。（注：非活动期间开通的用户无法享受此福利内容）
        </p>
        <p>3、奖励派发：活动结束后，将在11月30日系统自动派送对应的现金奖励至您的账户（奖金可提现）。</p>
        <p>4、查看奖励：【我的】—【我的钱包】—【有鱼零钱】中查看您的现金奖励记录。</p>
        <p>注：会员为特殊性服务商品，开通后概不支持退换，敬请悉知！</p>
      </div>
      <div class="rule-module">
        <p class="bold">福利二活动规则</p>
        <p class="red">1、活动时间：2021.11.08  00:00:00 ~ 2021.11.11  24:00:00</p>
        <p>
          2、活动内容：活动期间在【发现页面】—【热门话题】—“1年仅1次，何不任性点”话题下方发表评论 ，分享并邀请好友为自己的评论点赞，评论获赞最高的前三名分别获得：
        </p>
        <p class="red">一等奖100元话费     二等奖50元话费     三等奖20元话费</p>
        <p>注：</p>
        <p>1、奖品不可转让，不可更换、不可折现。</p>
        <p>2、包括但不限于使用任何技术手段等非常规操作获得奖品，一经发现平台有权扣除所有奖励。</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Acivity',
  mounted() {
    document.title = '11.11超级钜惠日';
  },
  methods: {
    jumpAction(status) {
      try {
        if (/i(os|phone|pad)/i.test(navigator.userAgent)) { // IOS
          let url = '';
          if (status === 'vip') {
            url = 'yyjz://Vip/center';
          } else {
            url = 'yyjz://Topic/detail?topicId=efc75a4cabd1462788cb7f2d2b098753';
          }
          window.location.href = url;
        } else if (/android/i.test(navigator.userAgent)) {
          // android
          let url = '';
          if (status === 'vip') {
            url = 'jz://com.caiyi.accounting/com.caiyi.accounting.jz.vip.VipCenterActivity';
          } else {
            url = 'jz://com.caiyi.accounting/com.caiyi.accounting.jz.TopicDetailActivity?topicId=efc75a4cabd1462788cb7f2d2b098753';
          }
          window.android.goToActivity2(url);
        }
      } catch (error) {
        console.log('调用客户端失败：' + error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
  .acivity-module {
    width: 100%;
    img {
      width: 100%;
    }
    .acivity-tips {
      font-size: 21px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #D52A2A;
      line-height: 32px;
      text-align: center;
      margin-top: 20px;
    }
    .frame {
      width: 672px;
      margin: 35px auto;
      position: relative;
      .btn {
        width: 266px;
        position: absolute;
        height: 67px;
        border-radius: 39px;
        background-color: rgb(206, 55, 46);
        font-size: 30px;
        color: #fff;
        bottom: 30px;
        left: 50%;
        margin-left: -133px;
        text-align: center;
        font-weight: 800;
        line-height: 67px;
      }
    }
    .acivity-rule {
      width: 672px;
      margin: 25px auto 35px;
      .rule-module:last-child {
        margin-top: 40px;
        padding-bottom: 40px;
      }
      p {
        font-size: 24px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #757575;
        line-height: 32px;
        margin-bottom: 4px;
        &.bold {
          font-weight: bold;
          color: #757575;
          line-height: 45px;
          font-size: 30px;
          margin-bottom: 6px;
        }
        &.red {
          font-weight: bold;
          color: #FF8585;
        }
      }
    }
  }
</style>
